'use client'

import { sendCardAcquisitionRequest, sendPaymentRequest } from '@/utils/adyenUtils'
import { MessageReference } from '@adyen/api-library/lib/src/typings/terminal/models'
import { TerminalApiResponse } from '@adyen/api-library/lib/src/typings/terminal/terminalApiResponse'
import { AnimatePresence, motion } from 'framer-motion'
import Link from 'next/link'
import { useEffect, useMemo, useRef, useState } from 'react'
import ReactConfetti from 'react-confetti'
import { z } from 'zod'
import { AdyenResults } from '../components/AdyenResults'
import { Footer } from '../components/Footer'
import { Logo } from '../components/Logo'
import { NorthernLights } from '../components/NorthernLights'
import { PaymentButtons } from '../components/PaymentButtons'
import { PaymentInput } from '../components/PaymentInput'
import { extractLoyaltyId, extractLoyaltyProgram } from '../utils/loyaltyUtils'
import { generateServiceId } from '../utils/serviceId'
import { lookupCustomer } from './actions/customer'

const RESET_IDENTIFIED_DELAY_MS = 7000

const purchaseAmountSchema = z.number().min(0).max(10000)

const CONFETTI_COLORS = [
	'#c0fff1',
	'#b6ffe7',
	'#acffdd',
	'#a2ffd3',
	'#98ffc9',
	'#8efebf',
	'#7aeaab',
	'#70e0a1',
	'#66d697',
]

const CONFETTI_ANIMATION = {
	INITIAL: { opacity: 0 },
	ANIMATE: { opacity: 1 },
	EXIT: { opacity: 0 },
	TRANSITION: { duration: 1 },
}

const HEADING_ANIMATION = {
	INITIAL: { opacity: 0, x: -150, y: -150, scale: 0 },
	ANIMATE: { opacity: 1, x: 0, y: 0, scale: 1 },
	EXIT: { opacity: 0, x: -50, y: -50 },
	TRANSITION: { duration: 2, type: 'spring', stiffness: 100, damping: 20, mass: 2 },
}

export default function IndexPage() {
	const [paymentLoading, setPaymentLoading] = useState(false)
	const [acquisitionLoading, setAcquisitionLoading] = useState(false)
	const [terminalResponse, setTerminalResponse] = useState<TerminalApiResponse>()
	const [purchaseAmount, setPurchaseAmount] = useState(1)
	const [selectedPOI, setSelectedPOI] = useState('')
	const [identified, setIdentified] = useState(false)
	const [customerName, setCustomerName] = useState('')
	const [responseHidden, setResponseHidden] = useState(true)
	const [currentServiceId, setCurrentServiceId] = useState('')
	const [error, setError] = useState('')
	const [requestType, setRequestType] = useState<MessageReference.MessageCategoryEnum>(
		MessageReference.MessageCategoryEnum.Payment
	)

	// Refs for focus management
	const mainContentRef = useRef<HTMLDivElement>(null)
	const debugButtonRef = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		setCurrentServiceId(generateServiceId())
	}, [])

	/**
	 * Sets the identified state to true and resets it after a specified duration.
	 *
	 * @param {string} name - The name of the customer.
	 */
	function setIdentifiedAndReset(name = 'Anonymous Customer') {
		setIdentified(true)
		setCustomerName(name)

		const timeoutId = setTimeout(() => {
			setIdentified(false)
			setCustomerName('')
		}, RESET_IDENTIFIED_DELAY_MS)

		return () => clearTimeout(timeoutId)
	}

	useEffect(() => {
		async function checkForLoyaltyPrograms() {
			try {
				if (!terminalResponse?.SaleToPOIResponse) {
					return
				}

				const program = extractLoyaltyProgram(terminalResponse)
				if (!program) {
					return
				}

				const loyaltyId = extractLoyaltyId(program)
				if (!loyaltyId) {
					setIdentified(false)
					setCustomerName('')
					return
				}

				try {
					const response = await lookupCustomer({ customerId: loyaltyId })
					const { displayName } = JSON.parse(response)
					setIdentifiedAndReset(displayName)
				} catch (error) {
					console.error('Error looking up customer:', error)
					setIdentified(false)
					setCustomerName('')
				}
			} catch (error) {
				console.error('Error in checking for loyalty programs:', error)
			}
		}

		void checkForLoyaltyPrograms()
	}, [terminalResponse])

	useEffect(() => {
		setTerminalResponse(undefined)
	}, [selectedPOI])

	/**
	 * Handles the payment request with input validation and error handling.
	 */
	async function handlePaymentRequest() {
		try {
			setError('')
			const validatedAmount = purchaseAmountSchema.parse(purchaseAmount)

			if (!selectedPOI) {
				setError('Please select a payment terminal first.')
				return
			}

			const newServiceId = generateServiceId()
			setCurrentServiceId(newServiceId)
			setRequestType(MessageReference.MessageCategoryEnum.Payment)

			// Add a timeout for the payment request
			const timeoutMs = 30000 // 30 seconds
			const timeoutPromise = new Promise((_, reject) => {
				setTimeout(() => reject(new Error('Payment request timed out')), timeoutMs)
			})

			const paymentPromise = sendPaymentRequest(
				validatedAmount,
				selectedPOI,
				newServiceId,
				setTerminalResponse,
				setPaymentLoading
			)

			await Promise.race([paymentPromise, timeoutPromise])
		} catch (error) {
			if (error instanceof z.ZodError) {
				setError('Invalid purchase amount. Please enter a valid amount between 0 and 10000.')
				return
			}

			// Handle specific error messages
			const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred'
			if (errorMessage.includes('timed out')) {
				setError('Payment request timed out. Please try again.')
			} else if (errorMessage.includes('Invalid response')) {
				setError('Terminal not responding. Please check the connection and try again.')
			} else {
				setError('Payment request failed. Please try again later.')
			}

			console.error('Payment request failed:', error)
		}
	}

	/**
	 * Handles the card acquisition request with input validation and error handling.
	 */
	async function handleCardAcquisitionRequest() {
		try {
			setError('')
			const validatedAmount = purchaseAmountSchema.parse(purchaseAmount)

			if (!selectedPOI) {
				setError('Please select a payment terminal first.')
				return
			}

			const newServiceId = generateServiceId()
			setCurrentServiceId(newServiceId)
			setRequestType(MessageReference.MessageCategoryEnum.CardAcquisition)

			// Add a timeout for the card acquisition request
			const timeoutMs = 30000 // 30 seconds
			const timeoutPromise = new Promise((_, reject) => {
				setTimeout(() => reject(new Error('Card acquisition request timed out')), timeoutMs)
			})

			const acquisitionPromise = sendCardAcquisitionRequest(
				selectedPOI,
				setTerminalResponse,
				setAcquisitionLoading,
				validatedAmount,
				newServiceId,
				validatedAmount === 0
			)

			await Promise.race([acquisitionPromise, timeoutPromise])
		} catch (error) {
			if (error instanceof z.ZodError) {
				setError('Invalid purchase amount. Please enter a valid amount between 0 and 10000.')
				return
			}

			// Handle specific error messages
			const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred'
			if (errorMessage.includes('timed out')) {
				setError('Card acquisition request timed out. Please try again.')
			} else if (errorMessage.includes('Invalid response')) {
				setError('Terminal not responding. Please check the connection and try again.')
			} else {
				setError('Card acquisition request failed. Please try again later.')
			}

			console.error('Card acquisition request failed:', error)
		}
	}

	/**
	 * Toggles the visibility of the debug response.
	 */
	function handleSetResponseHidden() {
		setResponseHidden((previousHidden) => !previousHidden)
		// Focus management for debug panel
		if (responseHidden) {
			debugButtonRef.current?.focus()
		}
	}

	const welcomeMessage = useMemo(
		() =>
			customerName ? (
				<motion.h1
					className="mt-8 text-3xl font-bold tracking-tight text-white sm:mt-14 sm:text-4xl md:mt-16 md:text-6xl"
					initial={HEADING_ANIMATION.INITIAL}
					animate={HEADING_ANIMATION.ANIMATE}
					exit={HEADING_ANIMATION.EXIT}
					transition={HEADING_ANIMATION.TRANSITION}
					role="heading"
					aria-level={1}
				>
					Welcome back,
					<br />
					<span className="bg-gradient-to-r from-[#c0fff1] to-[#66d697] bg-clip-text text-transparent">
						{customerName} <span aria-hidden="true">👋🏼</span>
					</span>
				</motion.h1>
			) : (
				<h1
					className="mt-8 text-3xl font-bold tracking-tight text-white sm:mt-14 sm:text-4xl md:mt-16 md:text-6xl"
					role="heading"
					aria-level={1}
				>
					Discover the Stellar Universe of{' '}
					<span className="bg-gradient-to-r from-[#c0fff1] to-[#66d697] bg-clip-text text-transparent">
						Stell <span aria-hidden="true">✨</span>
					</span>
				</h1>
			),
		[customerName]
	)

	const errorMessage = useMemo(() => {
		if (!error) {
			return
		}
		return (
			<div
				role="alert"
				className="mt-4 rounded-lg bg-red-500/10 p-4 text-sm text-red-200"
				aria-live="assertive"
				aria-atomic="true"
			>
				{error}
			</div>
		)
	}, [error])

	const confetti = useMemo(() => {
		if (!identified) {
			return
		}
		return (
			<motion.div
				initial={CONFETTI_ANIMATION.INITIAL}
				animate={CONFETTI_ANIMATION.ANIMATE}
				exit={CONFETTI_ANIMATION.EXIT}
				transition={CONFETTI_ANIMATION.TRANSITION}
			>
				<ReactConfetti gravity={0.5} colors={CONFETTI_COLORS} />
			</motion.div>
		)
	}, [identified])

	useEffect(() => {
		function handleEscapeKey(event: KeyboardEvent) {
			if (event.key === 'Escape' && !responseHidden) {
				handleSetResponseHidden()
			}
		}

		window.addEventListener('keydown', handleEscapeKey)
		return () => window.removeEventListener('keydown', handleEscapeKey)
	}, [responseHidden])

	return (
		<main className="relative flex min-h-screen flex-col" role="main">
			{/* Skip link for keyboard users */}
			<a
				href="#main-content"
				className="sr-only focus:not-sr-only focus:absolute focus:left-4 focus:top-4 focus:z-50 focus:rounded-lg focus:bg-white focus:p-4 focus:text-black focus:outline-none focus:ring-2 focus:ring-[#66d697]"
			>
				Skip to main content
			</a>

			<NorthernLights aria-hidden="true" />

			<div
				id="main-content"
				ref={mainContentRef}
				className="relative isolate mx-auto w-full max-w-7xl grow overflow-hidden px-4 py-6 sm:px-6 sm:py-10 lg:flex lg:px-8 lg:pt-20"
				tabIndex={-1}
			>
				<div className="mx-auto max-w-2xl shrink-0 lg:mx-0 lg:max-w-2xl lg:pt-8">
					<nav aria-label="Main navigation">
						<Link
							href="/"
							title="Return to Stell homepage"
							className="inline-block min-h-[44px] min-w-[44px] focus:outline-none focus:ring-2 focus:ring-[#66d697] focus:ring-offset-2 focus:ring-offset-black"
						>
							<Logo className="h-6 fill-white sm:h-8" aria-label="Stell logo" />
							<span className="sr-only">Stell home</span>
						</Link>
					</nav>

					<AnimatePresence>{welcomeMessage}</AnimatePresence>

					<p className="mt-4 text-sm leading-7 text-white/70 sm:mt-6 sm:text-base sm:leading-8">
						Step into the future with Stell, where the universe of customer identification and payments
						unfolds seamlessly.
					</p>

					{errorMessage}

					<form
						className="mt-6 flex flex-col gap-y-4 sm:mt-10 sm:gap-y-6"
						onSubmit={(event) => {
							event.preventDefault()
							if (purchaseAmount > 0) {
								handlePaymentRequest()
							}
						}}
						aria-label="Payment form"
					>
						<PaymentInput purchaseAmount={purchaseAmount} setPurchaseAmount={setPurchaseAmount} />
						<PaymentButtons
								sendPaymentRequest={handlePaymentRequest}
								sendCardAcquisitionRequest={handleCardAcquisitionRequest}
								paymentLoading={paymentLoading}
								acquisitionLoading={acquisitionLoading}
								selectedPOI={selectedPOI}
								setSelectedPOI={setSelectedPOI}
								amount={purchaseAmount}
							/>
					</form>
				</div>
			</div>

			<Footer />

			<button
				ref={debugButtonRef}
				type="button"
				className="fixed right-4 top-4 min-h-[44px] min-w-[44px] rounded-md bg-white/5 px-3 py-2 text-sm text-white/40 transition-colors hover:bg-white/10 hover:text-white focus:outline-none focus:ring-2 focus:ring-[#66d697] focus:ring-offset-2 focus:ring-offset-black"
				onClick={handleSetResponseHidden}
				aria-expanded={!responseHidden}
				aria-controls="debug-response"
				aria-haspopup="dialog"
			>
				<span aria-hidden="true">⚠︎</span> {responseHidden ? 'Show' : 'Hide'} response
			</button>

			{!responseHidden && (
				<div
					id="debug-response"
					className="fixed inset-x-0 bottom-0 max-h-[40vh] overflow-y-auto bg-white/5 backdrop-blur-md"
					role="dialog"
					aria-label="Debug response panel"
					aria-modal="true"
					aria-describedby="debug-response-description"
					tabIndex={0}
				>
					<div id="debug-response-description" className="sr-only">
						This panel shows the technical response from the payment terminal. Press Escape to close.
					</div>
					<AdyenResults formattedResponse={terminalResponse} isPaymentComplete={!!terminalResponse} />
				</div>
			)}

			<AnimatePresence>{confetti}</AnimatePresence>
		</main>
	)
}
