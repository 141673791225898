import { processCardAcquisition } from '@/app/actions/cardAcquisition'
import { processDisplay } from '@/app/actions/display'
import { processPayment } from '@/app/actions/payment'
import { TerminalApiResponse } from '@adyen/api-library/lib/src/typings/terminal/models'

/**
 * Asynchronously sends a card acquisition request to the server. Updates the payment response and loading
 * state based on the server's response.
 *
 * @param {string} selectedPOI - The selected POI.
 * @param {Function} setPaymentResponse - The setter function for payment response.
 * @param {Function} setAcquisitionLoading - The setter function for acquisition loading.
 * @returns {Promise<void>}
 */
export async function sendCardAcquisitionRequest(
	selectedPOI: string,
	setPaymentResponse: (response: TerminalApiResponse | undefined) => void,
	setAcquisitionLoading: (loading: boolean) => void,
	purchaseAmount: number,
	serviceId: string,
	vasOnly = false
): Promise<void> {
	setPaymentResponse(undefined)
	setAcquisitionLoading(true)

	try {
		const serializedResponse = await processCardAcquisition({
			amount: purchaseAmount,
			POIID: selectedPOI,
			serviceId,
			vasOnly,
		})

		const response = JSON.parse(serializedResponse) as TerminalApiResponse

		console.info('Card acquisition response data', response)
		setPaymentResponse(response)
	} catch (error) {
		console.error('Error sending card acquisition request', error)
		setPaymentResponse(undefined)
	} finally {
		setAcquisitionLoading(false)
	}
}

/**
 * Asynchronously sends a payment request to the server. Updates the payment response and loading state based
 * on the server's response.
 *
 * @param {number} purchaseAmount - The amount to purchase.
 * @param {string} selectedPOI - The selected POI.
 * @param {Function} setPaymentResponse - The setter function for payment response.
 * @param {Function} setPaymentLoading - The setter function for payment loading.
 * @returns {Promise<void>}
 */
export async function sendPaymentRequest(
	purchaseAmount: number,
	selectedPOI: string,
	serviceId: string,
	setPaymentResponse: (response: TerminalApiResponse | undefined) => void,
	setPaymentLoading: (loading: boolean) => void
): Promise<void> {
	setPaymentResponse(undefined)
	setPaymentLoading(true)

	try {
		const serializedResponse = await processPayment({
			amount: purchaseAmount,
			POIID: selectedPOI,
			serviceId,
		})

		const response = JSON.parse(serializedResponse) as TerminalApiResponse

		setPaymentResponse(response)
	} catch (error) {
		console.error('Error sending payment request', error)
		setPaymentResponse(undefined)
	} finally {
		setPaymentLoading(false)
	}
}

export async function sendDisplayRequest(request: unknown): Promise<void> {
	try {
		console.debug('Preparing to send display request')

		const serializedResponse = await processDisplay(request)
		const response = JSON.parse(serializedResponse)

		console.info('Display request response', response)

		if (!response) {
			console.error('Display request failed')
			return
		}

		console.debug('Display request completed successfully')
	} catch (error) {
		console.error('Error sending display request', error)
	}
}
