/**
 * Generates a unique service ID using crypto.
 * Uses Web Crypto API for better security and browser compatibility.
 *
 * @param {number} bytes - The number of bytes to be used in the ID generation.
 * @returns {string} The newly generated service ID.
 */
function generateServiceId(bytes = 4): string {
	const array = new Uint8Array(bytes)
	crypto.getRandomValues(array)
	return [...array].map((b) => b.toString(16).padStart(2, '0')).join('')
}

export { generateServiceId }