import { TerminalApiResponse } from '@adyen/api-library/lib/src/typings/terminal/models'
import { motion } from 'framer-motion'
import { useMemo } from 'react'

const initialMotion = { opacity: 0 }
const transitionMotion = { duration: 0.5 }

export function AdyenResults({
	formattedResponse: terminalResponse,
	isPaymentComplete,
}: Readonly<{
	formattedResponse: TerminalApiResponse | undefined
	isPaymentComplete: boolean
}>) {
	const animateMotion = useMemo(() => ({ opacity: isPaymentComplete ? 1 : 0 }), [isPaymentComplete])

	const formattedJson = useMemo(() => {
		if (!terminalResponse) return ''
		return JSON.stringify(terminalResponse, undefined, 2)
	}, [terminalResponse])

	return (
		<motion.div
			initial={initialMotion}
			animate={animateMotion}
			transition={transitionMotion}
			className="relative"
		>
			{isPaymentComplete ? (
				<div className="mx-auto w-full max-w-7xl p-4 sm:px-6 lg:px-8">
					<pre className="overflow-x-auto p-4 text-sm leading-6 text-white/70">
						<code>{formattedJson}</code>
					</pre>
				</div>
			) : undefined}
		</motion.div>
	)
}
