import { useCallback, useEffect, useMemo } from 'react'

const AMOUNT_PRESETS = [
	{ label: '0 kr', value: 0 },
	{ label: '100 kr', value: 100 },
	{ label: '200 kr', value: 200 },
	{ label: '500 kr', value: 500 },
	{ label: '1000 kr', value: 1000 },
]

const KEYBOARD_SHORTCUTS = {
	UP: ['ArrowUp', 'k'],
	DOWN: ['ArrowDown', 'j'],
}

const INCREMENT_VALUE = 10

export function PaymentInput({
	purchaseAmount,
	setPurchaseAmount,
}: {
	purchaseAmount: number
	setPurchaseAmount: React.Dispatch<React.SetStateAction<number>>
}) {
	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			// Remove all non-digit characters and parse
			const digits = '0123456789'
			const rawValue = [...event.target.value].filter((char) => digits.includes(char)).join('')
			const value = Number.parseInt(rawValue || '0')
			setPurchaseAmount(value)
		},
		[setPurchaseAmount]
	)

	const formattedAmount = useMemo(() => {
		return new Intl.NumberFormat('no-NO').format(purchaseAmount)
	}, [purchaseAmount])

	function handlePresetClick(amount: number) {
		setPurchaseAmount(amount)
	}

	function handleIncrement() {
		setPurchaseAmount((current) => (current < 10 ? current + 9 : current + INCREMENT_VALUE))
	}

	function handleDecrement() {
		setPurchaseAmount((current) => Math.max(0, current - INCREMENT_VALUE))
	}

	// Handle keyboard shortcuts
	useEffect(() => {
		function handleKeyDown(event: KeyboardEvent) {
			// Ignore if focus is in an input
			if (event.target instanceof HTMLInputElement) {
				return
			}

			if (KEYBOARD_SHORTCUTS.UP.includes(event.key)) {
				event.preventDefault()
				handleIncrement()
			} else if (KEYBOARD_SHORTCUTS.DOWN.includes(event.key)) {
				event.preventDefault()
				handleDecrement()
			} else if (event.key >= '1' && event.key <= '4') {
				// Number keys 1-4 for presets
				event.preventDefault()
				const presetIndex = Number(event.key) - 1
				const preset = AMOUNT_PRESETS[presetIndex]
				if (preset) {
					handlePresetClick(preset.value)
				}
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [])

	return (
		<div className="space-y-6">
			<div className="space-y-2">
				<div className="relative flex items-center">
					<div className="absolute inset-y-0 left-0 flex items-stretch">
						<button
							type="button"
							onClick={handleDecrement}
							className="z-10 flex h-full w-14 items-center justify-center rounded-l-md border-r border-white/10 bg-white/5 text-lg text-white/50 transition-colors hover:bg-white/10 hover:text-white focus:outline-none focus:ring-2 focus:ring-[#66d697] focus:ring-offset-2 focus:ring-offset-black disabled:cursor-not-allowed disabled:opacity-50"
							disabled={purchaseAmount === 0}
							aria-label={`Decrease amount by ${INCREMENT_VALUE} kr (↓ or j)`}
						>
							-
						</button>
					</div>
					<div className="relative flex-1">
						<div className="pointer-events-none absolute inset-y-0 left-14 flex items-center pl-6">
							<span className="text-3xl text-white/50">kr</span>
						</div>
						<input
							type="text"
							inputMode="numeric"
							pattern="[0-9]*"
							name="price"
							id="price"
							className="block h-14 w-full rounded-md border-0 bg-white/5 px-20 text-center text-3xl text-white caret-[#66d697] ring-0 transition-colors placeholder:text-white/30 focus:bg-white/10 focus:outline-none focus:ring-2 focus:ring-[#66d697] focus:ring-offset-2 focus:ring-offset-black sm:text-4xl"
							value={formattedAmount}
							onChange={handleInputChange}
							aria-label="Purchase amount in Norwegian Krone"
						/>
					</div>
					<div className="absolute inset-y-0 right-0 flex items-stretch">
						<button
							type="button"
							onClick={handleIncrement}
							className="z-10 flex h-full w-14 items-center justify-center rounded-r-md border-l border-white/10 bg-white/5 text-lg text-white/50 transition-colors hover:bg-white/10 hover:text-white focus:outline-none focus:ring-2 focus:ring-[#66d697] focus:ring-offset-2 focus:ring-offset-black"
							aria-label={`Increase amount by ${INCREMENT_VALUE} kr (↑ or k)`}
						>
							+
						</button>
					</div>
				</div>
			</div>

			<div className="flex flex-wrap gap-2">
				{AMOUNT_PRESETS.map(({ label, value }, index) => (
					<button
						key={value}
						type="button"
						onClick={() => handlePresetClick(value)}
						className="flex-1 rounded-md bg-white/5 px-4 py-2 text-sm text-white/70 transition-colors hover:bg-white/10 hover:text-white focus:outline-none focus:ring-2 focus:ring-[#66d697] focus:ring-offset-2 focus:ring-offset-black"
						aria-label={`Set amount to ${label} (Press ${index + 1})`}
					>
						{label}
					</button>
				))}
			</div>
		</div>
	)
}
