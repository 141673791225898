/**
 * Interface for Point of Interest (POI)
 *
 * @property {string} id - The unique identifier of the POI
 * @property {string} model - The model of the POI
 * @property {string} name - The name of the POI
 * @property {string} location - The location of the POI (optional)
 * @interface
 */
interface PointOfInterest {
	id: string
	model: string
	name: string
	location?: string
}

/**
 * Array of available Point of Interest (POI)
 *
 * @type {PointOfInterest[]}
 */
export const availablePointsOfInterest: PointOfInterest[] = [
	{
		id: 'P400Plus-804231941',
		model: 'Verifone P400Plus',
		name: 'P400Plus-804231941',
		location: 'Stell (Stavanger)',
	},
	{
		id: 'P400Plus-804228109',
		model: 'Verifone P400Plus',
		name: 'P400Plus-804228109',
		location: 'Stell (Oslo)',
	},
	{
		id: 'S1F2-000158224617152',
		model: 'Castles S1F2',
		name: 'S1F2-000158224617152',
		location: 'Stell (Stavanger)',
	},
]
