import { motion } from 'framer-motion'

/**
 * The style object for the Northern Lights animation. It defines a clipPath to create the desired visual
 * effect.
 */
const style = {
	clipPath:
		'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
}

/**
 * The animate object for the Northern Lights animation. It defines the scale, rotation, y-axis position, and
 * opacity changes over time.
 */
const animate = {
	scale: [1, 1.5, 1],
	rotate: [0, 5, 0],
	y: [0, 50, -100, 0],
	opacity: [0.15, 0.25, 0.15],
}

/**
 * The transition object for the Northern Lights animation. It defines the duration of the animation and the
 * number of times the animation should repeat.
 */
const transition = { duration: 30, repeat: Number.POSITIVE_INFINITY }

/**
 * This function returns a div element that represents the Northern Lights animation. The div element has a
 * gradient background and a specific clipPath to create the desired visual effect.
 *
 * @returns A JSX.Element representing the Northern Lights animation.
 */
export function NorthernLights(): JSX.Element {
	return (
		<>
			<svg
				className="absolute inset-0 -z-10 size-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
				aria-hidden="true"
			>
				<defs>
					<pattern
						id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
						width={200}
						height={200}
						x="50%"
						y={-1}
						patternUnits="userSpaceOnUse"
					>
						<path d="M.5 200V.5H200" fill="none" />
					</pattern>
				</defs>
				<svg x="50%" y={-1} className="overflow-visible fill-primary-800/20">
					<path
						d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
						strokeWidth={0}
					/>
				</svg>
				<rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
			</svg>
			<div
				className="absolute left-[calc(20%-4rem)] top-10 -z-40 transform-gpu blur-3xl sm:left-[calc(70%-18rem)] lg:left-48 lg:top-[calc(60%-30rem)] xl:left-[calc(50%-24rem)]"
				aria-hidden="true"
			>
				<motion.div
					className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#c0fff1] to-[#66d697] opacity-20"
					style={style}
					animate={animate}
					transition={transition}
				/>
			</div>
		</>
	)
}
