import Link from 'next/link'

export function Footer() {
	return (
		<footer aria-labelledby="footer-heading" className="relative">
			<h2 id="footer-heading" className="sr-only">
				Footer
			</h2>
			<div className="mx-auto max-w-7xl px-6 pb-8 pt-4 lg:px-8">
				<div className="border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
					<div className="flex items-center gap-4">
						<Link href="/pos" className="text-sm text-gray-400 transition-colors hover:text-white">
							POS Demo
						</Link>
					</div>
					<p className="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
						&copy; 2024 <Link href="https://getstell.com">Stell Tech AS</Link>. All rights reserved.
					</p>
				</div>
			</div>
		</footer>
	)
}
