import { LoyaltyAccount } from '@adyen/api-library/lib/src/typings/terminal/models'
import { TerminalApiResponse } from '@adyen/api-library/lib/src/typings/terminal/terminalApiResponse'

export const LOYALTY_BRAND = process.env.LOYALTY_BRAND

if (!LOYALTY_BRAND) {
	throw new Error('No loyalty brand defined in the environment variables')
}

/**
 * Extracts the loyalty account from the acquisition response.
 *
 * @param response - The terminal API response.
 * @returns The loyalty account if found, otherwise undefined.
 */
function getLoyaltyAccountFromAcquisitionResponse(response: TerminalApiResponse): LoyaltyAccount | undefined {
	return response.SaleToPOIResponse?.CardAcquisitionResponse?.LoyaltyAccount?.find(
		(loyaltyAccount) => loyaltyAccount.LoyaltyBrand === LOYALTY_BRAND
	)
}

/**
 * Extracts the loyalty account from the payment response.
 *
 * @param response - The terminal API response.
 * @returns The loyalty account if found, otherwise undefined.
 */
function getLoyaltyAccountFromPaymentResponse(response: TerminalApiResponse): LoyaltyAccount | undefined {
	// Extract the loyalty result from the payment response or the repeated response message body
	const loyaltyResult =
		response.SaleToPOIResponse?.PaymentResponse?.LoyaltyResult ??
		response.SaleToPOIResponse?.TransactionStatusResponse?.RepeatedMessageResponse
			?.RepeatedResponseMessageBody.PaymentResponse?.LoyaltyResult

	// Find and return the loyalty account that matches the loyalty brand
	return loyaltyResult?.find((loyalty) => loyalty.LoyaltyAccount.LoyaltyBrand === LOYALTY_BRAND)
		?.LoyaltyAccount
}

/**
 * Extracts the loyalty account from the terminal API response.
 *
 * @param response - The terminal API response.
 * @returns The loyalty account if found, otherwise undefined.
 */
function getLoyaltyAccountFromResponse(response: TerminalApiResponse): LoyaltyAccount | undefined {
	return getLoyaltyAccountFromPaymentResponse(response) || getLoyaltyAccountFromAcquisitionResponse(response)
}

/**
 * Extracts the loyalty program from the terminal API response.
 *
 * @param response - The terminal API response.
 * @returns The loyalty account if found, otherwise undefined.
 */
export function extractLoyaltyProgram(response?: TerminalApiResponse): LoyaltyAccount | undefined {
	if (!response?.SaleToPOIResponse) {
		console.error('Invalid response object')
		return undefined
	}

	const loyaltyAccount = getLoyaltyAccountFromResponse(response)

	if (!loyaltyAccount) {
		console.log('No loyalty program found in the payment or acquisition responses')
	}

	return loyaltyAccount
}

/**
 * Extracts the loyalty ID from the provided loyalty account.
 *
 * @param account - The loyalty account from which to extract the ID.
 * @returns The loyalty ID if found, otherwise undefined.
 */
export function extractLoyaltyId(account?: LoyaltyAccount): string | undefined {
	return account?.LoyaltyAccountID?.LoyaltyID
}
